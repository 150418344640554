import Header from "./header/Header";
import Post from "./post/Post";
import Topbar from "./topbar/Topbar";

import "./App.css";
import Footer from "./footer/Footer";

function App() {
  return (
    <>
      <Topbar />
      <Header />
      <div className="content">
        <Post />
      </div>
      <Footer />
    </>
  );
}

export default App;
