import "./post.css";

export default function Post() {
  return (
    <div className="post">
      <div className="card">
        <img src="./images/image.jpeg" alt="post" />
        <div className="postBody">
          <h1>
            <a href="/">
              Learn How to Pre-render Pages Using Static Generation with Next.js
              .
            </a>
          </h1>
          <span>June 02,2024</span>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit hoc
            ultimum bonorum, quod nunc a me defenditur; Vitae autem degendae
            ratio maxime quidem illis placuit quieta. Duo Reges: constructio
            interrete. Nam quid possumus facere melius?
          </p>
          <div className="userProfile">
            <img src="./images/user.png" alt="profile" />
            <span>Username</span>
          </div>
        </div>
      </div>
      <div className="card">
        <img src="./images/image.jpeg" alt="post" />
        <div className="postBody">
          <h1>
            <a href="/">
              Learn How to Pre-render Pages Using Static Generation with Next.js
              .
            </a>
          </h1>
          <span>June 02,2024</span>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit hoc
            ultimum bonorum, quod nunc a me defenditur; Vitae autem degendae
            ratio maxime quidem illis placuit quieta. Duo Reges: constructio
            interrete. Nam quid possumus facere melius?
          </p>
          <div className="userProfile">
            <img src="./images/user.png" alt="profile" />
            <span>Username</span>
          </div>
        </div>
      </div>
    </div>
  );
}
