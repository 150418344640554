import "./footer.css";

export default function Footer() {
  return (
    <div className="footer">
      <div className="left">
        <h1>Statically Generated with Next.js.</h1>
      </div>
      <div className="right">
        <a href="/" className="button">
          Read Documentation
        </a>
        <a href="/" className="footerLink">
          View on GitHub
        </a>
      </div>
    </div>
  );
}
