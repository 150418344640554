import "./header.css";

export default function Header() {
  return (
    <div className="header">
      <img src="./images/image.jpeg" alt="header" />
      <div className="headerBody">
        <div className="left">
          <h1>
            <a href="/">From Server-side Rendering to Static Generation</a>
          </h1>
          <span>Jun 2,2024</span>
        </div>
        <div className="right">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quid
            dubitas igitur mutare principia naturae? Ita multo sanguine profuso
            in laetitia et in victoria est mortuus. Omnia contraria, quos etiam
            insanos esse vultis. Hinc ceteri particulas arripere conati suam
            quisque videro voluit afferre sententiam. Quis non odit sordidos,
            vanos, leves, futtiles? Quasi ego id curem, quid ille aiat aut
            neget.
          </p>
          <div className="userProfile">
            <img src="./images/user.png" alt="profile" />
            <span>Username</span>
          </div>
        </div>
      </div>
    </div>
  );
}
