import "./topbar.css";

export default function Topbar() {
  return (
    <nav>
      <h1>Blog.</h1>
      <h4>
        A statically generated blog example using <a href="/">React.js</a>.
      </h4>
    </nav>
  );
}
